.container.cascading {
  #livePics {
    position: relative;
    min-height: 150px;
  }
  #liveDetails {
    position: relative;
    min-height: 150px;

    .description {
      max-height: 100px;
      overflow: hidden;
    }
    .winningBid {
      font-size: 20px;
      margin-top:3px;
    }
    .winningBidder2 {
      font-size: 12px;
    }
  }
  #upcomingList {
    .description {
      max-height: 100px;
      overflow: hidden;
    }
    .row {
      -webkit-transition: max-height .5s, opacity.25s;
      -moz-transition: max-height .5s, opacity.25s;
      -ms-transition: max-height .5s, opacity.25s;
      -o-transition: max-height .5s, opacity.25s;
      transition: max-height .5s, opacity.25s;
      max-height: 900px;
    }
    .completed {
      max-height: 0;
      overflow: hidden;
      opacity: .25;
    }

    &.showCompleted {
      .completed {
        max-height: 900px;
        opacity: 1;
      }
    }
    .bidInfoBox {
      width:100%;
      margin-top: -8px;
      margin-bottom:5px;
      
      .winningBidder {
        font-size:13px;
        float: right;
      }
    }
  }

  .bidBox {
    position: absolute;
    bottom: 0px;
    padding-bottom: 20px;
    width: 96%;
  }

  .hidden-a {
    max-height: 0;
    overflow: hidden;
  }
}
