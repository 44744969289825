#errPage h1 {
  font-size: 100px;
}
.hasError {
  border-color: red!important;
}

.table-striped tbody tr:nth-child(even) {
  background-color: #F3F3F4;
}

.btn {
  .btn-primary-outline {
    color: #0275d8;
    background-color: transparent;
    background-image: none;
    border-color: #0275d8;
  }

  .fa-spin { display: none; }
  &[disabled] .fa-spin { display: inline; }
}

.card {
  box-shadow: 0px 1px 3px #ccc,0px 0px 3px #ececec;
  border-radius: 0;
  background-color: #FFF;
  display: inline-block;
  margin: 6px 2px;
  padding: 5px 9px;

  &.card-clear {
    box-shadow: none;
  }
  &.card-full {
    padding-left: 0px;
    padding-right: 0px;
  }
}
a.card {
  cursor: pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  box-shadow: 0px 1px 3px #b5b3b3,0px 0px 3px #e0dfdf;
  background-color: #eeeeee;

  &:hover {
    background-color: #e2e0e0;
    text-decoration: none;
  }
  &:active {
    box-shadow: 0px -1px 3px #ccc,0px 1px 3px #ececec;
    transform: translateY(1px);
  }
}

.contain-i-e-s {
  position:relative;
}
.text-i-e-s {
  text-align:center;
  position:absolute;
  color: #FFF;
  top: 9px;
  left: 0;
  right: 0;
  text-shadow: 0px 0px 6px #000;
  font-size: 8px;
}

a.selectCombo {
  color:#000;
  cursor:pointer;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  &:hover {
    font-weight: bold;
  }
  &:active {
    color:#464646;
  }
}

.nav-tabs {
  margin-top: 20px;
}

.tab-pane {
  margin-top: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    padding-bottom: 45px;
    margin-bottom: 15px;
  }
}

.flex-fill{
  display: flex;
  flex-direction: column;

  > div {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @for $i from 1 through 12 {
      &.flex-#{$i} {
        flex: $i;
      }
    }

    &.flex-center {
      justify-content: center;
    }
    &.flex-bottom {
      justify-content: flex-end;
    }
  }

  &.flex-fill-full {
    height: 100vh;

    @media (max-width: 768px) {
      height: auto;
    }
  }
}

.display-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .auction-tile-two:not(:first-child) {
    @media (min-width: 1000px) {
      width: 32%!important;
      margin-left: 1%;
    }
    @media (max-height: 1000px) {
      width: 49%;
      margin-left: 1%;
    }
    @media (max-width: 500px) {
      width: 100%;
      margin: 0;
    }
  }

  .auction-tile-two:first-child {
    @media (min-width: 1000px) {
      width: 32%!important;
      margin-right: 1%;
    }
    @media (max-height: 1000px) {
      width: 49%;
      margin-right: 1%;
    }
    @media (max-width: 500px) {
      width: 100%;
      margin: 0;
    }
  }

  .flex-two {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;

    .item-1 {
      width: 100%;
      a {
        width: 100%;
        img {
          width: 100% !important;
          height: auto;
        }
      }
    }
    .item-2 {
      padding-left: 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      .auction-description {
        flex-grow: 1;
      }
      p {
        align-self: flex-end;
      }
    }
  }
}

.bottom-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pull-bottom {
  position: absolute;
  bottom: 0;
}

.intl-tel-input {
  width:100%;
}

.clickable {
  cursor: pointer;
}

.documentList {
  .file {
    width: 120px;
    display: inline-block;
    align-items: stretch;
    text-align: center;
    padding: 10px 5px 4px 5px;
    border-radius: 5px;
    background: #f5f5f5;
    border: 1px solid #e8e8e8;
    margin: 4px 2px;
    text-decoration: none;

    &:hover {
      background: #e8e8e8;
    }
    .image {
      position: relative;
      height: 106px;
      width: 120px;
      display: inline-block;
      overflow: hidden;
      margin-left: -6px;
      margin-top: -12px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      i {
        font-size: 4em;
        margin: 25px 0;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .filename {
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      display: block;
    }
  }
}

.pswp__item iframe {
  display: block;
  margin: 0 auto;
  padding-top: 44px;
  width: 100%;
  height: 100%;
  border: 0;
}
