#winningBids {
  i.fa-history:before, i.fa-trash:before { margin-left: 5px; }
  i.fa-gavel:before { margin-right: 5px; }
  a { text-decoration: none; cursor: pointer; }
  a:hover { font-weight: normal; }
  .unit { position: relative; top: -2px; font-size: 12px; }
}
#multiparBids {
  i.fa-history:before, i.fa-trash:before { margin-left: 5px; }
  i.fa-gavel:before { margin-right: 5px; }
  i.fa-check { color: #ccc; font-size: 16px; }
  i.fa-check.winning { color: green; }
  a { text-decoration: none; cursor: pointer; }
  a:hover { font-weight: normal; }
}
#bidHistoryBox {
  max-height: 500px;
  overflow-y: auto;
  i.fa-trash:before { margin-left: 5px; }
  i.fa-gavel:before { margin-right: 5px; }
  i.fa-check { color: #ccc; font-size: 16px; }
  i.fa-check.winning { color: green; }
  a { text-decoration: none; cursor: pointer; }
  a:hover { font-weight: normal; }
  .unit { position: relative; top: -2px; font-size: 12px; }
  .time { position: relative; top: -2px; font-size: 12px; }
}

#bidHistory {
  i.fa-gavel:before { margin-right: 5px; }
  i.fa-check { color: #ccc; font-size: 16px; }
  i.fa-check.winning { color: green; }
  .row.striped-row:last-child { border-radius: 0 0 6px 6px; }
  .unit { position: relative; top: -2px; font-size: 12px; }
  .time { position: relative; top: -2px; font-size: 12px; }
}

#pendingBids {
  i.fa-check-circle { color: green; font-size: 16px; }
  i.fa-times-circle { color: red; font-size: 16px; }
  a { text-decoration: none; cursor: pointer; }
}

#multiparMessages {
  max-height: 520px;
  overflow-y: scroll;
}

.bid-count, .auction-total {
  margin-left: 10px;
  float: right;
  text-align: right;
  div { font-size: 18px; font-weight: bold; border: 1px solid #ccc; padding: 1px 6px 1px 16px; border-radius: 4px; min-width: 100px; }
  span { font-size: 12px; padding-right: 6px; }
}

.multi-par-lot {
  padding: 8px 0 !important;
  &.selectable { cursor: pointer !important; }
  a { text-decoration: none; cursor: pointer; }
  a:hover { font-weight: normal; }
  .media-main { max-width: 33%; margin-right: 8px; margin-left: 25px; }
  .checkbox { margin: 0; position: absolute; top: 50%; margin-top: -10px; }
  .checkbox input { cursor: pointer; }
  .checkbox input[type="checkbox"]:focus + label::before { outline: 0; }
  .description {
    overflow-y: hidden;
    max-height: 4em;
    p { margin: 0; }
  }
}

.bidInfo {
  .amount { font-weight: bold; font-size: 16px; }
  .unit { position: relative; top: -5px; font-size: 12px; }
  .desc { font-style: italic; font-size: 12px; }
}

#multipar-full-map {
  width: 100%;
  height: 100%;

  display: flex;

  #map {
    position: relative;
    flex: 1 1 auto;

    canvas {
      position: absolute;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
    }

    canvas#map-base { z-index: 0; }
    canvas#map-tiles { z-index: 1; }
  }

  #tiles {
    width: 300px;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .tile {
      width: 140px;
      height: 120px;
      margin: 5px;

      &.no-coords {
        .lotnum {
          &::before, &::after {
            content: '*';
          }
        }
      }

      .inner-tile {
        background: rgba(255, 255, 255, 0.85);
        margin: 2px;
        height: calc(100% - 4px);
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .bidder {
          span {
            font-size: 12px;
            display: block;
          }
        }
        .amount {
          font-size: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
