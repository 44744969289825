#bidLoading {
  position: absolute;
  right: -15px;
  top: 27px;
  font-size: 20px;
}
#userLoading {
  text-align:center;
  margin-top:100px;
  margin-bottom:100px;
  font-size: 20px;
}

.loading-overlay {
  position: absolute;
  left: -15px;
  right: -15px;
  z-index: 999;
  padding: 50px 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.13);
  height: 100%;
}
.spinner {
  animation: rotator 1.4s linear infinite;
  width: 30px;
  height: 30px;
}

#mainLoading .spinner {
  width: 50px;
  height: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke: #132C40;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
