.container.simulcast {
  .main-grid {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;

      .lot-card { order: 1; min-height: 40vh; }
      .history-card { order: 2; min-height: 40vh; }
      .bid-card { order: 3; min-height: 40vh; }
      .notification-card { order: 4; min-height: 40vh; }
    }

    .card {
      margin-left: 5px;
      margin-right: 5px;
    }

    .auction-activity {
      flex: 2 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > * {
        flex: 1 0 40%;
      }

      @media (max-width: 767px) {
        flex: 0 1 auto;
        flex-direction: column;
      }
    }
    .lot-list {
      flex: 1 0 0;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        max-height: 80vh;
      }
    }

    .bid-card {
      display: flex;
      flex-direction: column;
      padding: 15px;

      #bidInfo {
        .winningBid {
          font-size: 20px;
          margin-top:3px;
          margin-right: 7px;
        }
      }
    }
  }

  #upcomingList {
    overflow-y: auto;
    flex: 1 1 auto;

    img {
      max-height: 200px;
      margin: 0 auto;
    }
    .description {
      max-height: 40px;
      overflow: hidden;
    }
    .row {
      -webkit-transition: opacity.25s;
      -moz-transition: opacity.25s;
      -ms-transition: opacity.25s;
      -o-transition: opacity.25s;
      transition: opacity.25s;
      max-height: 300px;
      padding: 5px;
      

      &.active {
        background: #d3ebff;
      }

      & + .row {
        border-top: 1px solid #ccc;
      }
    }
    h3 {
      margin-top: 0;
      font-size: 20px;
    }
    h2 {
      margin-top: 0;
    }
    .description, .description p {
      font-size: 12px;
    }
  }

  #askingIncrements {
    margin-top: 10px;

    h4.bar {
      margin: 5px -15px 5px;

      a {
        color: #FFF;
        cursor: pointer;
      }
    }

    .increments a{
      margin: 2px;
    }
  }

  #approval {
    margin-top: 10px;

    > h4.bar {
      margin: 5px -15px 5px;

      .checkbox {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0px;
      }
    }
  }

  #pendingApproval {
    max-height: 75px;
    overflow-y: scroll;
    width:100%;
    overflow-x: hidden;

    > div {
      font-size:12px;

      a {
        margin-bottom: 2px;
      }
    }
  }

  .notification-card {
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #auctionNotifications {
      flex: 1 1 auto;
    }
  }

  #auctionNotifications {
    position: relative;
    margin-bottom: 2px;
    overflow-y: scroll;
    margin: 0 0 0 10px;
  }

  .history-card {
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    
    #bidHistory {
      flex: 1 1 auto;
    }
  }
  #bidHistory {
    position: relative;
    overflow-y: scroll;
    margin: 0 0 0 10px;
  }

  .setActive {
    margin-left: 5px;
    cursor:pointer;
  }

  #livePics {
    position: relative;

    .media-main {
      width: calc(100% - 20px);
      padding-top: 10px;
      margin: 0 auto;
    }
  }

  #liveDetails {
    position: relative;

    .description {
      max-height: 125px;
      overflow: hidden;
    }
    .title {
      font-size: 28px;
    }
  }

  .bidBox {
    margin-top: 15px;
  }
  .video-box + .bidBox {
    margin-top: 0;
  }

  @media (max-width: 400px) {
    .video-box {
      display: none
    }
  }
  /*
  

  .bidBox {
    padding-bottom: 20px;
    width: 96%;
  }

  .container-wide {
    width: 100%;
    max-width: 1400px;
  }

  
*/
}

.simulcast-container-rework{
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  @media (min-width: 768px){
    width: 750px;
  }

  @media (min-width: 992px){
    width: 970px;
  }

  @media (min-width: 1200px){
    width: 1170px;
  }

  @media (min-width: 1450px){
    width: 1400px;
  }

  .disable-click{
    cursor: pointer;
  }
  display: flex;
  .image-tile-link{
    cursor: pointer !important;
  }
  .lot-picker-header{
    text-align: center;
    >hr{
      margin: 0px;
    };
    >h2{
      margin: 10px;
    }
  }
  .simulcast-block{
    &.admin{
      max-height: 82vh;
    }
  }
  .card{
    width: 100%;
    &.messages{
      height: 275px;
      padding: 10px 0 0 0;
      #multiparMessages{
        height: 210px;
        padding: 0 9px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      #newMultiparMessage{
        padding: 11px 10px;
        background-color: #f4f4f4;
      }
      &.admin{
        height: 40vh;
        #multiparMessages{
          height: calc(40vh - 65px);
        }
      }
    }

    &.bid-history{
      max-height: 25vh;
      overflow-y: scroll;
      .bid-row{
        margin-left: 2%;
        text-align: start;
        display: flex;
        .user-col{
          width: 40%;
        }
        .bid-col{
          width: 50%;
        }
        .icons-col{
          width: 10%;
        }
      }

    }

    #multiparMessages{

      overflow-y: scroll;
      overflow-x: hidden;
    }
    #newMultiparMessages{
      height: 10vh!important;

    }

    &.bid-box{
      &.admin{
        height: 55vh;
      }
    }
    .lot_tile{
      display: flex;
      &.chosen{
        background: #eaeffa
      }
      &.selected{
        border: 2px solid green;
        border-radius: 5px;
      }
      .lot_thumbnail{
        width: 20%;
      }
      .details{
        width: 78%;
        margin-left: 2%;
        .col-md-12{
          padding: 0px;
        }
        .col-md-6{
          padding: 0px;
        }
        .col-md-9{
          padding: 0px;
        }
        .col-md-3{
          padding: 0px;
        }
      }
      .lot-info{
        margin-top:-20px;
      }
    }
  }
    .lots-picker{
      overflow-y: scroll;
      &.admin{
        height: 80vh;
      }
    }
      
    .bid-info{
      text-align: center; 
      margin-top: 0px;
      .current-bid{
        &.highlighted{
          color: green;
          font-weight: bold;
        }
      }
    }

    &.message-tile{
      width: 98%; 
      margin: 0px;
      p{
        margin: 0px;
      }
    }


  .bidBtn{
    button{
      font-size: 18px;
    }
  }
}

.simulcast-container{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  .simulcast-block{
    // width: 49%;
    .lot-tile{
      &.selected{
        border: 2px solid green;
        border-radius: 5px;
      }
    }

    .simulcast-title-block{

    }
    .simulcast-main-image{

    }
    .image-slider{

    }
    .simulcast-button-bar{
      display: flex;
      justify-content: center;
    }
    .simulcast-lot-info{

    }
    .simulcast-choice{
      display: flex;
      flex-wrap: wrap;
      overflow-y: scroll;
      max-height: 250px;
      .choose-lot-tile{
        margin-bottom: 1%;
        margin-left: 0.5%;
        margin-right: 0.5%;
        width: 49%;
        display: flex;
        padding: 1%;
        &.selected{
          border: 2px solid green;
          border-radius: 5px;
        }
        .media-main{
          width: 50%;
        }
        .info{
          width: 50%;
          margin-left: 1%;
          .choose-lot-btn{
            padding: 6px;
            i.selected{
              color: green;
            }
          }
        }
      }
    }
    .bid-history{
      max-height: 200px;
      overflow-y: scroll;
    }
    #auctionNotifications{
      max-height: 200px;
      overflow-y: scroll;
    }
  }
}

.simulcast-admin-choice-picker{
  max-height: 400px;
  overflow-y: scroll;
  .lot-picker-tile{
    display: flex;
    .media-main{
      width: 30%;
    }
    .title{
      width: 40%;
    }
    .make-choice{
      width: 20%;
    }
  }
}

.admin-currently-chosen-lots{
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  tbody{
    tr{
      th{
        text-align: center;
      }
      td{
        padding:10px;
        &.lot-image{
          width: 20%;
        }
      }
    }
  }
}

#bidStatus, #openSimulcast {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  position: relative;
  padding: 0 44px;

  &.admin a{
    cursor: pointer;
  }
  &.not-admin a{
    cursor: default;
  }
  span, a{
    border:1px solid #C8C8C8;
    border-radius: 5px;
    padding: 6px 8px;
    margin: 3px;
    color: #000;
    text-decoration: none;
    background: #FFF;
  }
  .once.active {
    background-color: #42A316;
    color: #FFF;
    border-color: #42A316;
  }
  .selling.active {
    background-color: #FFBD32;
    color: #FFF;
    border-color: #FFBD32;
  }
  .sold.active {
    background-color: #d20d0d;
    color: #FFF;
    border-color: #d20d0d;
  }
  .end-choice.active {
    background-color: orangered;
    color: #FFF;
    border-color: orangered;
  }

  .pass {
    position: absolute;
    left: 0;
    top: -10px;
    padding: 5px 8px;
  }
}

.increment-buttons{
  .increment-selector{
    margin: 2px;
    
  }
}


.winner:not(.admin) {
  box-shadow: 0 0 10px 3px rgba(22,178,25,0.75);
}

.loser:not(.admin) {
  box-shadow: 0 0 10px 3px rgba(178,19,16,0.75);
}

#btn-startbroadcast.livestream-started {
  background-color: green !important;
  color: #FFF !important;
  border-color: green !important;
}

@keyframes onlinebid {
  0% { color: transparent;  }
  50% { color: red; }
  100% { color: transparent;  }
}

@keyframes onlinebidhistory {
  0% { box-shadow: 0 0 10px 3px rgba(178,19,16,0); }
  50% { box-shadow: 0 0 10px 3px rgba(178,19,16,0.75); }
  100% { box-shadow: 0 0 10px 3px rgba(178,19,16,0); }
}

.online-bid {
  color: transparent;
  animation-name: onlinebid;
  animation-duration: 1s;
  animation-iteration-count: 3;
}

.online-bid-history { 
  animation-name: onlinebidhistory;
  animation-duration: 1s;
  animation-iteration-count: 3;
}